/* Container styling */
.container-fluid{
    margin-top: -12vw;
}
.col-md-6 {
    padding: 20px;
    border-radius: 8px;
   
}

/* Form styling */
form {
    display: flex;
    flex-direction: column;
}

/* Form group styling */
.form-group {
    margin-bottom: 15px;
}

/* Label styling */
.form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    display: block;
}

/* Input and textarea styling */
.form-control {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    width: 100%;
}

/* Button styling */
.btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
}
