.contact-head{
    text-align: center;
    font-weight: bold;
    color: black;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.452);
    margin-top: 12vw;
    margin-bottom: 2vw;
}
@media(max-width: 767.98px) {
   .contact-head{
    margin-top: 5vh;
   }
}