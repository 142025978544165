/* Ensure there's space below the header */
.about-page {
  margin-top: 8vw; /* Adjust this value depending on the height of your header */
  width: 100%;
}

/* Styling for the top banner */
.about-banner {
  position: relative;
  text-align: center;
  margin-bottom: 30px;
}

.banner-image {
  width: 100%;
  height: 300px; /* You can adjust the height */
  object-fit: cover;
}

.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-overlay h1 {
  color: rgb(140, 96, 7);
  font-size: 3rem;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.541);
  font-weight: bold;
}

/* About page content */
.about-container {
  padding: 20px;
  max-width: 90%;
  margin: 0 auto;
}

.about-container h2 {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.about-container p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
}

/* Product grid */
.product-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-top: 20px;
}

.product-grid img {
  width: 100%;
  height: auto;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: transform 0.3s ease;
}

.product-grid img:hover {
  transform: scale(1.05);
}

/* View All button */
.view-all-button {
  text-align: center;
  margin-top: 30px;
}

.view-all-button button {
  padding: 10px 20px;
  font-size: 16px;
  color:black !important;
  background-color: #fef028 !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.view-all-button button:hover {
  background-color: #f4f02f;
}
.our-company-section {
  margin-top: 40px;
  text-align: center;
}

.video-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 20px;
}

.video-grid video {
  width: 55%;
  max-width: 400px;
  margin-bottom: 2vw;
}
